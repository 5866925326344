var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: { "main-en": _vm.$route.params.lang === "en" },
      attrs: { id: "Main" }
    },
    [
      _c(
        "div",
        { staticClass: "header", class: { opacity: _vm.scrollTop > 0 } },
        [
          _c("div", { staticClass: "header-space" }),
          _c("div", { staticClass: "header_is" }, [
            _c(
              "div",
              { staticClass: "header_warp" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "header_logo",
                    attrs: { to: "/" + this.$route.params.lang + "/Home" }
                  },
                  [_c("img", { attrs: { src: "/images/logo.png", alt: "" } })]
                ),
                _c("input", {
                  ref: "HeaderListSwitch",
                  staticClass: "checkbox-none",
                  attrs: { type: "checkbox", id: "HeaderListSwitch" }
                }),
                _vm._m(0),
                _c("ul", { staticClass: "header_list" }, [
                  _c("li", { staticClass: "header_list_item" }, [
                    _c(
                      "label",
                      {
                        attrs: { for: "MenuSelectRadioLabel1" },
                        on: { click: _vm.menuSelectRadioSwitch }
                      },
                      [
                        _c("input", {
                          staticClass: "checkbox-none",
                          attrs: {
                            id: "MenuSelectRadioLabel1",
                            name: "MenuSelectRadio",
                            type: "checkbox"
                          }
                        }),
                        _c(
                          "div",
                          { staticClass: "header-list-item-container" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "titleHeader",
                                attrs: {
                                  to: "/" + this.$route.params.lang + "/Home"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                                        " +
                                    _vm._s(_vm.$t("homeTitle.titleheader0")) +
                                    "\n                                    "
                                )
                              ]
                            ),
                            _c("ul", { staticClass: "list" }, [
                              _c("li", { staticClass: "list_utem" }, [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href:
                                        "/" +
                                        _vm.$route.params.lang +
                                        "/home#duction"
                                    },
                                    on: { click: _vm.setCheckboxState }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("homeTitle.listItem0.duction")
                                      )
                                    )
                                  ]
                                )
                              ]),
                              _c("li", { staticClass: "list_utem" }, [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href:
                                        "/" +
                                        _vm.$route.params.lang +
                                        "/home#activites"
                                    },
                                    on: { click: _vm.setCheckboxState }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("homeTitle.listItem0.activites")
                                      )
                                    )
                                  ]
                                )
                              ]),
                              _c("li", { staticClass: "list_utem" }, [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href:
                                        "/" +
                                        _vm.$route.params.lang +
                                        "/home#Pastreview"
                                    },
                                    on: { click: _vm.setCheckboxState }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("homeTitle.listItem0.Pastreview")
                                      )
                                    )
                                  ]
                                )
                              ]),
                              _c("li", { staticClass: "list_utem" }, [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href:
                                        "/" +
                                        _vm.$route.params.lang +
                                        "/home#Review"
                                    },
                                    on: { click: _vm.setCheckboxState }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("homeTitle.listItem0.Review")
                                      )
                                    )
                                  ]
                                )
                              ]),
                              _c("li", { staticClass: "list_utem" }, [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href:
                                        "/" +
                                        _vm.$route.params.lang +
                                        "/home#Media"
                                    },
                                    on: { click: _vm.setCheckboxState }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("homeTitle.listItem0.Media")
                                      )
                                    )
                                  ]
                                )
                              ]),
                              _c("li", { staticClass: "list_utem" }, [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href:
                                        "/" +
                                        _vm.$route.params.lang +
                                        "/home#CooperativeMedia"
                                    },
                                    on: { click: _vm.setCheckboxState }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "homeTitle.listItem0.CooperativeMedia"
                                        )
                                      )
                                    )
                                  ]
                                )
                              ])
                            ])
                          ],
                          1
                        )
                      ]
                    )
                  ]),
                  _c("li", { staticClass: "header_list_item" }, [
                    _c(
                      "label",
                      {
                        attrs: { for: "MenuSelectRadioLabel2" },
                        on: { click: _vm.menuSelectRadioSwitch }
                      },
                      [
                        _c("input", {
                          staticClass: "checkbox-none",
                          attrs: {
                            id: "MenuSelectRadioLabel2",
                            name: "MenuSelectRadio",
                            type: "checkbox"
                          }
                        }),
                        _c(
                          "div",
                          { staticClass: "header-list-item-container" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "titleHeader",
                                attrs: {
                                  to:
                                    "/" +
                                    this.$route.params.lang +
                                    "/exhibitorsService"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                                        " +
                                    _vm._s(_vm.$t("homeTitle.titleheader1")) +
                                    "\n                                    "
                                )
                              ]
                            ),
                            _c("ul", { staticClass: "list" }, [
                              _c("li", { staticClass: "list_utem" }, [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href:
                                        "/" +
                                        _vm.$route.params.lang +
                                        "/exhibitorsService#exhibition"
                                    },
                                    on: { click: _vm.setCheckboxState }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("homeTitle.listItem1.exhibition")
                                      )
                                    )
                                  ]
                                )
                              ]),
                              _c("li", { staticClass: "list_utem" }, [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href:
                                        "/" +
                                        _vm.$route.params.lang +
                                        "/exhibitorsService#Floorplan"
                                    },
                                    on: { click: _vm.setCheckboxState }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("homeTitle.listItem1.Floorplan")
                                      )
                                    )
                                  ]
                                )
                              ]),
                              _c("li", { staticClass: "list_utem" }, [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href:
                                        "/" +
                                        _vm.$route.params.lang +
                                        "/exhibitorsService#DownloadCenter"
                                    },
                                    on: { click: _vm.setCheckboxState }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "homeTitle.listItem1.DownloadCenter"
                                        )
                                      )
                                    )
                                  ]
                                )
                              ]),
                              _c("li", { staticClass: "list_utem" }, [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href:
                                        "/" +
                                        _vm.$route.params.lang +
                                        "/exhibitorsService#CooperativeExhibitors"
                                    },
                                    on: { click: _vm.setCheckboxState }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "homeTitle.listItem1.CooperativeExhibitors"
                                        )
                                      )
                                    )
                                  ]
                                )
                              ]),
                              _c("li", { staticClass: "list_utem" }, [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href:
                                        "/" +
                                        _vm.$route.params.lang +
                                        "/exhibitorsService#Sponsor"
                                    },
                                    on: { click: _vm.setCheckboxState }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("homeTitle.listItem1.Sponsor")
                                      )
                                    )
                                  ]
                                )
                              ]),
                              _c("li", { staticClass: "list_utem" }, [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href:
                                        "/" +
                                        _vm.$route.params.lang +
                                        "/exhibitorsService#traffic"
                                    },
                                    on: { click: _vm.setCheckboxState }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("homeTitle.listItem1.traffic")
                                      )
                                    )
                                  ]
                                )
                              ])
                            ])
                          ],
                          1
                        )
                      ]
                    )
                  ]),
                  _c("li", { staticClass: "header_list_item" }, [
                    _c(
                      "label",
                      {
                        attrs: { for: "MenuSelectRadioLabel3" },
                        on: { click: _vm.menuSelectRadioSwitch }
                      },
                      [
                        _c("input", {
                          staticClass: "checkbox-none",
                          attrs: {
                            id: "MenuSelectRadioLabel3",
                            name: "MenuSelectRadio",
                            type: "checkbox"
                          }
                        }),
                        _c(
                          "div",
                          { staticClass: "header-list-item-container" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "titleHeader",
                                attrs: {
                                  to:
                                    "/" +
                                    this.$route.params.lang +
                                    "/buyerservice"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                                        " +
                                    _vm._s(_vm.$t("homeTitle.titleheader2")) +
                                    "\n                                    "
                                )
                              ]
                            ),
                            _c("ul", { staticClass: "list" }, [
                              _c("li", { staticClass: "list_utem" }, [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href:
                                        "/" +
                                        _vm.$route.params.lang +
                                        "/buyerservice#RegistrationOfExhibitors"
                                    },
                                    on: { click: _vm.setCheckboxState }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "homeTitle.listItem2.RegistrationOfExhibitors"
                                        )
                                      )
                                    )
                                  ]
                                )
                              ]),
                              _c("li", { staticClass: "list_utem" }, [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href:
                                        "/" +
                                        _vm.$route.params.lang +
                                        "/buyerservice#ScopeOfExhibition"
                                    },
                                    on: { click: _vm.setCheckboxState }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "homeTitle.listItem2.ScopeOfExhibition"
                                        )
                                      )
                                    )
                                  ]
                                )
                              ]),
                              _c("li", { staticClass: "list_utem" }, [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href:
                                        "/" +
                                        _vm.$route.params.lang +
                                        "/buyerservice#ExhibitorsDirectory"
                                    },
                                    on: { click: _vm.setCheckboxState }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "homeTitle.listItem2.ExhibitorsDirectory"
                                        )
                                      )
                                    )
                                  ]
                                )
                              ]),
                              _c("li", { staticClass: "list_utem" }, [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href:
                                        "/" +
                                        _vm.$route.params.lang +
                                        "/buyerservice#TransportationAccommodation"
                                    },
                                    on: { click: _vm.setCheckboxState }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "homeTitle.listItem2.TransportationAccommodation"
                                        )
                                      )
                                    )
                                  ]
                                )
                              ])
                            ])
                          ],
                          1
                        )
                      ]
                    )
                  ]),
                  _c("li", { staticClass: "header_list_item" }, [
                    _c(
                      "label",
                      {
                        attrs: { for: "MenuSelectRadioLabel4" },
                        on: { click: _vm.menuSelectRadioSwitch }
                      },
                      [
                        _c("input", {
                          staticClass: "checkbox-none",
                          attrs: {
                            id: "MenuSelectRadioLabel4",
                            name: "MenuSelectRadio",
                            type: "checkbox"
                          }
                        }),
                        _c(
                          "div",
                          { staticClass: "header-list-item-container" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "titleHeader",
                                attrs: {
                                  to:
                                    "/" +
                                    this.$route.params.lang +
                                    "/conferenceactivities"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                                        " +
                                    _vm._s(_vm.$t("homeTitle.titleheader3")) +
                                    "\n                                    "
                                )
                              ]
                            ),
                            _c("ul", { staticClass: "list" }, [
                              _c("li", { staticClass: "list_utem" }, [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href:
                                        "/" +
                                        _vm.$route.params.lang +
                                        "/conferenceactivities#MainActivity"
                                    },
                                    on: { click: _vm.setCheckboxState }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "homeTitle.listItem3.MainActivity"
                                        )
                                      )
                                    )
                                  ]
                                )
                              ]),
                              _c("li", { staticClass: "list_utem" }, [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href:
                                        "/" +
                                        _vm.$route.params.lang +
                                        "/conferenceactivities#Organizingactivities"
                                    },
                                    on: { click: _vm.setCheckboxState }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "homeTitle.listItem3.Organizingactivities"
                                        )
                                      )
                                    )
                                  ]
                                )
                              ]),
                              _c("li", { staticClass: "list_utem" }, [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href:
                                        "/" +
                                        _vm.$route.params.lang +
                                        "/conferenceactivities#ProductRecommendation"
                                    },
                                    on: { click: _vm.setCheckboxState }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "homeTitle.listItem3.ProductRecommendation"
                                        )
                                      )
                                    )
                                  ]
                                )
                              ])
                            ])
                          ],
                          1
                        )
                      ]
                    )
                  ]),
                  _c("li", { staticClass: "header_list_item" }, [
                    _c("div", { staticClass: "lang-switch-item" }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: _vm.$route.path.replace(/^\/en/i, "/zh")
                          }
                        },
                        [_vm._v("中")]
                      ),
                      _vm._v("/\n                                "),
                      _c(
                        "a",
                        {
                          attrs: {
                            href: _vm.$route.path.replace(/^\/zh/i, "/en")
                          }
                        },
                        [_vm._v("En")]
                      )
                    ])
                  ])
                ]),
                _c("div", { staticClass: "counting-container" }, [
                  _c("div", { staticClass: "timeruning" }, [
                    _c("div", { staticClass: "text-header" }, [
                      _c("div", { staticClass: "text-header-box" }, [
                        _c("div", { staticClass: "first" }, [
                          _c(
                            "a",
                            {
                              staticClass: "btn",
                              attrs: {
                                href:
                                  "/" +
                                  _vm.$route.params.lang +
                                  "/exhibitorsService#exhibition"
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("textHeader.Exhibitors")))]
                          )
                        ]),
                        _c("div", { staticClass: "last" }, [
                          _c(
                            "a",
                            {
                              staticClass: "btn",
                              attrs: {
                                href:
                                  "/" +
                                  _vm.$route.params.lang +
                                  "/buyerservice#RegistrationOfExhibitors"
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("textHeader.visit")))]
                          )
                        ])
                      ])
                    ])
                  ])
                ]),
                _c("div", { staticClass: "lang-switch" }, [
                  _c(
                    "a",
                    {
                      attrs: { href: _vm.$route.path.replace(/^\/en/i, "/zh") }
                    },
                    [_vm._v("中")]
                  ),
                  _vm._v("/\n                        "),
                  _c(
                    "a",
                    {
                      attrs: { href: _vm.$route.path.replace(/^\/zh/i, "/en") }
                    },
                    [_vm._v("En")]
                  )
                ])
              ],
              1
            )
          ])
        ]
      ),
      _c("router-view")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      {
        staticClass: "menu-button-label none",
        attrs: { for: "HeaderListSwitch" }
      },
      [
        _c("a", { staticClass: "menu-button" }, [
          _c("span", { staticClass: "icon-bar" }),
          _c("span", { staticClass: "icon-bar" }),
          _c("span", { staticClass: "icon-bar" })
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }