<template>
    <div id="Main" :class="{'main-en':$route.params.lang === 'en'}">
        <div class="header" :class="{opacity:scrollTop > 0}">
            <div class="header-space"></div>
            <div class="header_is">
                <div class="header_warp">
                    <router-link :to="`/${this.$route.params.lang}/Home`" class="header_logo">
                        <img src="/images/logo.png" alt="">
                    </router-link>
                    <input type="checkbox" ref="HeaderListSwitch" id="HeaderListSwitch" class="checkbox-none"/>
                    <label for="HeaderListSwitch" class="menu-button-label none">
                        <a class="menu-button">
                            <span class="icon-bar"></span>
                            <span class="icon-bar"></span>
                            <span class="icon-bar"></span>
                        </a>
                    </label>
                    <ul class="header_list">
                        <li class="header_list_item">
                            <label for="MenuSelectRadioLabel1" @click="menuSelectRadioSwitch">
                                <input id="MenuSelectRadioLabel1" name="MenuSelectRadio" type="checkbox"
                                       class="checkbox-none"/>
                                <div class="header-list-item-container">
                                    <router-link :to="`/${this.$route.params.lang}/Home`" class="titleHeader">
                                        {{$t('homeTitle.titleheader0')}}
                                    </router-link>
                                    <ul class="list">
                                        <li class="list_utem"><a @click="setCheckboxState"
                                                                 :href="`/${$route.params.lang}/home#duction`">{{$t('homeTitle.listItem0.duction')}}</a>
                                        </li>
                                        <li class="list_utem"><a @click="setCheckboxState"
                                                                 :href="`/${$route.params.lang}/home#activites`">{{$t('homeTitle.listItem0.activites')}}</a>
                                        </li>
                                        <li class="list_utem"><a @click="setCheckboxState"
                                                                 :href="`/${$route.params.lang}/home#Pastreview`">{{$t('homeTitle.listItem0.Pastreview')}}</a>
                                        </li>
                                        <li class="list_utem"><a @click="setCheckboxState"
                                                                 :href="`/${$route.params.lang}/home#Review`">{{$t('homeTitle.listItem0.Review')}}</a>
                                        </li>
                                        <li class="list_utem"><a @click="setCheckboxState"
                                                                 :href="`/${$route.params.lang}/home#Media`">{{$t('homeTitle.listItem0.Media')}}</a>
                                        </li>
                                        <li class="list_utem"><a @click="setCheckboxState"
                                                                 :href="`/${$route.params.lang}/home#CooperativeMedia`">{{$t('homeTitle.listItem0.CooperativeMedia')}}</a>
                                        </li>
                                    </ul>
                                </div>
                            </label>
                        </li>
                        <li class="header_list_item">
                            <label for="MenuSelectRadioLabel2" @click="menuSelectRadioSwitch">
                                <input id="MenuSelectRadioLabel2" name="MenuSelectRadio" type="checkbox"
                                       class="checkbox-none"/>
                                <div class="header-list-item-container">

                                    <router-link :to="`/${this.$route.params.lang}/exhibitorsService`"
                                                 class="titleHeader">
                                        {{$t('homeTitle.titleheader1')}}
                                    </router-link>
                                    <ul class="list">
                                        <li class="list_utem"><a @click="setCheckboxState"
                                                                 :href="`/${$route.params.lang}/exhibitorsService#exhibition`">{{$t('homeTitle.listItem1.exhibition')}}</a>
                                        </li>
                                        <li class="list_utem"><a @click="setCheckboxState"
                                                                 :href="`/${$route.params.lang}/exhibitorsService#Floorplan`">{{$t('homeTitle.listItem1.Floorplan')}}</a>
                                        </li>
                                        <li class="list_utem"><a @click="setCheckboxState"
                                                                 :href="`/${$route.params.lang}/exhibitorsService#DownloadCenter`">{{$t('homeTitle.listItem1.DownloadCenter')}}</a>
                                        </li>
                                        <li class="list_utem"><a @click="setCheckboxState"
                                                                 :href="`/${$route.params.lang}/exhibitorsService#CooperativeExhibitors`">{{$t('homeTitle.listItem1.CooperativeExhibitors')}}</a>
                                        </li>
                                        <li class="list_utem"><a @click="setCheckboxState"
                                                                 :href="`/${$route.params.lang}/exhibitorsService#Sponsor`">{{$t('homeTitle.listItem1.Sponsor')}}</a>
                                        </li>
                                        <li class="list_utem"><a @click="setCheckboxState"
                                                                 :href="`/${$route.params.lang}/exhibitorsService#traffic`">{{$t('homeTitle.listItem1.traffic')}}</a>
                                        </li>
                                    </ul>
                                </div>
                            </label>
                        </li>
                        <li class="header_list_item">
                            <label for="MenuSelectRadioLabel3" @click="menuSelectRadioSwitch">
                                <input id="MenuSelectRadioLabel3" name="MenuSelectRadio" type="checkbox"
                                       class="checkbox-none"/>
                                <div class="header-list-item-container">

                                    <router-link :to="`/${this.$route.params.lang}/buyerservice`" class="titleHeader">
                                        {{$t('homeTitle.titleheader2')}}
                                    </router-link>

                                    <ul class="list">
                                        <li class="list_utem"><a @click="setCheckboxState"
                                                                 :href="`/${$route.params.lang}/buyerservice#RegistrationOfExhibitors`">{{$t('homeTitle.listItem2.RegistrationOfExhibitors')}}</a>
                                        </li>
                                        <li class="list_utem"><a @click="setCheckboxState"
                                                                 :href="`/${$route.params.lang}/buyerservice#ScopeOfExhibition`">{{$t('homeTitle.listItem2.ScopeOfExhibition')}}</a>
                                        </li>
                                        <li class="list_utem"><a @click="setCheckboxState"
                                                                 :href="`/${$route.params.lang}/buyerservice#ExhibitorsDirectory`">{{$t('homeTitle.listItem2.ExhibitorsDirectory')}}</a>
                                        </li>
                                        <li class="list_utem"><a @click="setCheckboxState"
                                                                 :href="`/${$route.params.lang}/buyerservice#TransportationAccommodation`">{{$t('homeTitle.listItem2.TransportationAccommodation')}}</a>
                                        </li>
                                    </ul>
                                </div>
                            </label>
                        </li>
                        <li class="header_list_item">
                            <label for="MenuSelectRadioLabel4" @click="menuSelectRadioSwitch">
                                <input id="MenuSelectRadioLabel4" name="MenuSelectRadio" type="checkbox"
                                       class="checkbox-none"/>
                                <div class="header-list-item-container">
                                    <router-link :to="`/${this.$route.params.lang}/conferenceactivities`"
                                                 class="titleHeader">
                                        {{$t('homeTitle.titleheader3')}}
                                    </router-link>
                                    <ul class="list">
                                        <!--<li class="list_utem">-->
                                        <!--    <a :href="`/${$route.params.lang}/Summit`">高峰会议</a>-->
                                        <!--</li>-->
                                        <li class="list_utem"><a @click="setCheckboxState"
                                                                 :href="`/${$route.params.lang}/conferenceactivities#MainActivity`">{{$t('homeTitle.listItem3.MainActivity')}}</a>
                                        </li>
                                        <li class="list_utem"><a @click="setCheckboxState"
                                                                 :href="`/${$route.params.lang}/conferenceactivities#Organizingactivities`">{{$t('homeTitle.listItem3.Organizingactivities')}}</a>
                                        </li>
                                        <li class="list_utem"><a @click="setCheckboxState"
                                                                 :href="`/${$route.params.lang}/conferenceactivities#ProductRecommendation`">{{$t('homeTitle.listItem3.ProductRecommendation')}}</a>
                                        </li>
                                    </ul>
                                </div>
                            </label>
                        </li>
                        <li class="header_list_item">
                            <div class="lang-switch-item">
                                <a :href="$route.path.replace(/^\/en/i,'/zh')">中</a>/
                                <a :href="$route.path.replace(/^\/zh/i,'/en')">En</a>
                            </div>
                        </li>
                    </ul>
                    <div class="counting-container">
                        <div class="timeruning">
                            <div class="text-header">
                                <div class="text-header-box">
                                    <div class="first">
                                        <a :href="`/${$route.params.lang}/exhibitorsService#exhibition`"
                                           class="btn"
                                        >{{$t('textHeader.Exhibitors')}}</a>
                                    </div>
                                    <div class="last">
                                        <a :href="`/${$route.params.lang}/buyerservice#RegistrationOfExhibitors`"
                                           class="btn">{{$t('textHeader.visit')}}</a>
                                    </div>
                                </div>
                            </div>
<!--                            <div class="time_text">-->
<!--                                <p> {{$t('textHeader.timeText.item',[day])}}</p>-->
<!--                            </div>-->
                        </div>
                    </div>
                    <div class="lang-switch">
                        <a :href="$route.path.replace(/^\/en/i,'/zh')">中</a>/
                        <a :href="$route.path.replace(/^\/zh/i,'/en')">En</a>
                    </div>
                </div>
            </div>
        </div>
        <router-view></router-view>
    </div>
</template>
<script>
    if (typeof window !== 'undefined') {
        require('amfe-flexible');
    }

    export default {
        name: 'pm-distribution',
        beforeRouteEnter(to, from, next) {
            next(vm => vm.$root.$i18n.locale = to.params.lang);
        },
        beforeRouteUpdate(to, from, next) {
            this.$root.$i18n.locale = to.params.lang;
            next();
        },
        data() {
            return {
                day: 0,
                hr: 0,
                min: 0,
                sec: 0,
                scrollTop: typeof window === 'undefined' ? null : window.scrollY
            }
        },
        created() {
            this.countdown();
        },
        mounted() {
            window.onscroll = () => {
                this.scrollTop = window.scrollY;
            };

            // 无条件覆盖
            const queryChannel = this.$route.query.r;
            if (queryChannel) {
                localStorage.setItem('conmart.channel', queryChannel);
            }
        },
        methods: {
            setCheckboxState(e) {
                this.$refs.HeaderListSwitch.checked = false;
            },
            menuSelectRadioSwitch({currentTarget}) {
                const radio = currentTarget.children[0];
                const element = document.getElementsByClassName('header_list')[0];
                [...document.getElementsByName('MenuSelectRadio')].filter(_ => _ !== radio).forEach(_ => _.checked = false);
                radio.checked = !radio.checked;

                if (radio.checked) {
                    element.className += ' header-list-show';
                } else {
                    element.className = element.className.split(' ').filter(_ => _ !== 'header-list-show').join(' ');
                }
            },
            changeLocale() {
                if (this.$route.params.lang === 'zh') {
                    window.location.href = this.$route.path.replace(/^\/zh\//i, '/en/')
                    // this.$router.replace(this.$route.path.replace(/^\/zh\//i, '/en/'))
                } else if (this.$route.params.lang === 'en') {
                    window.location.href = this.$route.path.replace(/^\/en\//i, '/zh/')
                    // this.$router.replace(this.$route.path.replace(/^\/en\//i, '/zh/'))
                }
            },
            countdown: function () {
                // 定义结束时间戳
                // const end = +new Date('2020-06-17 00:00:00');
                const end = +new Date('2020/06/17 00:00:00');
                // 定义当前时间戳
                const now = +new Date();
                // 做判断当倒计时结束时都为0
                if (now >= end) {
                    this.day = 0;
                    return
                }
                const msec = end - now
                let day = parseInt(msec / 1000 / 60 / 60 / 24);
                //给数据赋值
                this.day = day
            }

        },

    }
</script>

<style lang="scss">
    //todo: 有个bug，窗口宽度拉伸在sm之间的时候，.list样式因transition过度效果而会出现.2s的opacity过渡效果。
    $header-height: 67;

    .header.opacity {
        .header_is {
            background: rgba(14, 15, 16, .8);
        }

        .header-space {
            background: transparent;
        }
    }

    .header-space,
    .header_is {
        height: #{$header-height}px;
    }

    .header_is {
        width: 100%;
        background: rgba(14, 15, 16, 1);
        transition: .2s linear;
        position: fixed;
        z-index: 999;
        text-align: center;
        font-size: 0;
        top: 0;

        .header_warp {
            display: inline-block;
            height: #{$header-height}px;
            color: rgba(255, 255, 255, 1);
            margin: auto;
            font-size: 14px;

            .header_logo {
                float: left;
                line-height: 67px;
                height: 67px;

                img {
                    display: inline-block;
                    vertical-align: middle;
                }
            }

            .header_list {
                float: left;
                padding-left: px2rem(298 - 37);

                .header-list-item-container {
                    max-height: 68px;
                    transition: none;
                }

                .header_list_item {
                    float: left;
                    position: relative;

                    height: 68px;
                    line-height: 68px;
                    text-align: center;

                    .titleHeader {
                        font-size: 16px;
                        font-weight: 400;
                        padding: 0 px2rem(37);
                        transition: color .2s linear;
                        height: 100%;
                        display: block;
                        position: relative;
                        z-index: 1;

                        &:hover {
                            color: lighten(#FFD300, 30%);
                        }

                        &.action {
                            color: #FFD300;
                        }
                    }


                    &:hover .list {
                        opacity: 1;
                        transform: translate(0, 0);
                        visibility: visible;
                    }

                    .list {
                        transition: .2s linear;
                        opacity: 0;
                        transform: translate(0, -5px);
                        visibility: hidden;
                        position: relative;
                        z-index: 0;

                        .list_utem {
                            height: 52px;
                            background: rgba(0, 0, 0, 1);
                            opacity: 0.7;
                            margin-bottom: 1px;
                            line-height: 52px;
                            text-align: center;
                            position: relative;

                            a {
                                display: block;
                                height: 100%;
                                font-family: Microsoft YaHei UI, sans-serif;
                                font-weight: 400;
                                color: rgba(204, 204, 204, 1);
                                transition: .15s linear;
                            }

                            &:after {
                                content: '';
                                display: block;
                                width: 4px;
                                height: 41px;
                                background: #FFD300;
                                position: absolute;
                                left: 6px;
                                top: 6px;
                                transform: scale(1, 0);
                                transition: .15s linear;
                                opacity: 0;
                            }

                            &:hover {
                                a {
                                    color: lighten(#FFD300, 30%);
                                }

                                &:after {
                                    opacity: 1;
                                    transform: scale(1, 1);
                                }
                            }
                        }
                    }
                }
            }

            .counting-container {
                position: relative;
                min-height: 67px;
                width: 289px;
                float: left;
                margin-right: px2rem(101);
            }

            .timeruning {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 51px;
                overflow: hidden;
                /*min-height: 99px;*/
                background: rgba(250, 139, 20, 1);
                /*border-radius: 0 0 16px 20px;*/
                z-index: 200 !important;
                padding-top: 16px;
                transition: .2s linear;
                transform: translate(0, 0);

                .text-header {
                    padding: 0 px2rem(22) 0 px2rem(22);
                    box-sizing: border-box;
                    width: 100%;
                    height: 100%;

                    .text-header-box {
                        overflow: hidden;
                        text-align: justify;

                        .btn {
                            width: 95%;
                            height: 35px;
                            border: 1px solid rgba(255, 255, 255, 1);
                            line-height: 35px;
                            text-align: center;
                            font-size: 14px;
                            font-weight: 400;
                            color: rgba(255, 255, 255, 1);
                            display: inline-block;
                            vertical-align: top;
                            transition: .2s linear;

                            &:hover {
                                color: #fa8b14;
                                background: #fff;
                            }
                        }

                        .first, .last {
                            float: left;
                            width: 50%;
                        }

                        .first {
                            text-align: left;
                        }

                        .last {
                            text-align: right;
                        }

                    }
                }

                .time_text {
                    padding: 0px 15px 16px 19px;
                    box-sizing: border-box;
                    font-size: 16px;
                    font-weight: 400;
                    color: rgba(255, 255, 255, 1);
                    height: 50px;
                    line-height: 58px;
                    text-align: left;

                    .time {
                        display: flex;
                        display: -webkit-box;
                        display: -moz-box;
                        display: -ms-flexbox;
                        display: -webkit-flex;

                        .huod {
                            margin-right: 2px;
                            height: 24px;
                            text-align: center;
                        }

                        span {
                            display: flex;
                            display: -webkit-box;
                            display: -moz-box;
                            display: -ms-flexbox;
                            display: -webkit-flex;
                            display: block;
                            // width:px;
                            height: 24px;
                            background: #121131;
                            color: rgba(255, 255, 255, 1);
                            line-height: 24px;
                            text-align: center;
                            border-radius: 3px;
                            margin: 0 1px;
                            padding: 0 1px;
                            box-sizing: border-box;
                        }

                        .colon {
                            text-align: left;
                            width: 7px;
                            font-weight: 600;
                            background: none;
                            margin: 0;
                        }
                    }

                }
            }

            .lang-switch-item {
                display: none;
            }

            .lang-switch, .lang-switch-item {
                float: left;
                cursor: pointer;
                line-height: 67px;
                font-size: 18px;
            }
        }
    }

    .header_list .header_list_item .router-link-active {
        color: #FFD300;
    }

    a {
        color: #ffffff;
    }

    .span {
        margin: 0 20px
    }

    .li {
        width: 300px;
        height: 200px;
        border: 1px solid black;
    }

    .content {
        width: 100%;

        .mainwarp {
            background: snow;

            .header {
                display: flex;
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                height: 50px;
                align-items: center;
                justify-content: space-between;

                .left {
                    ul {
                        display: flex;
                        display: -webkit-box;
                        display: -moz-box;
                        display: -ms-flexbox;
                        display: -webkit-flex;
                        width: 100px;
                        justify-content: space-between;
                    }
                }

                .right {
                    ul {
                        display: flex;
                        display: -webkit-box;
                        display: -moz-box;
                        display: -ms-flexbox;
                        display: -webkit-flex;
                        width: 100px;
                        justify-content: space-between;
                    }
                }
            }
        }
    }

    @include lg {
        .header_is {
            .header_warp {
                .header_list {
                    padding-left: px2rem((298-37) / 2);
                }

                .counting-container {
                    margin-right: px2rem(25);
                }
            }
        }
    }

    @include md {
        .header_is .header_warp {
            .counting-container {
                display: none;
                //.timeruning {
                //   transform: translate(0, -100%);
                //}
            }
        }
    }

    @include sm {
        .header-space {
            display: none;
        }

        .header_is {
            position: relative;
            height: auto;
        }

        .header_is {
            .header_warp {
                height: auto;
                display: block;

                .header_list {
                    border-top: 1px solid #777;
                    float: none;
                    display: block;
                    overflow: hidden;
                    width: 100%;
                    padding: 0;
                    max-height: 0;
                    transition: max-height .2s linear;

                    li {
                        display: block;
                        float: none;
                        width: 100%;

                        .lang-switch-item {
                            display: block;
                            float: none;
                        }
                    }

                    .header_list_item {
                        height: auto;
                        overflow: hidden;
                        max-width: 100%;

                        .titleHeader {

                        }

                        .list {
                            opacity: 1;
                            transform: translate(0, 0);
                            visibility: visible;
                        }

                        .header-list-item-container {
                            transition: max-height .3s linear;
                        }

                        input:checked + .header-list-item-container {
                            max-height: 68px * 5;
                        }
                    }
                }


                #HeaderListSwitch:checked + label {
                    //看时间情况可以做个小动画把三道杠改成x
                    > a {
                        /*background: #dddddd;*/
                    }

                    + .header_list {
                        /*max-height: unset;*/
                        max-height: 400px;

                        &.header-list-show {
                            max-height: 620px;
                        }
                    }
                }
            }

            .header_logo {
                margin-left: px2rem(40);
            }

            .lang-switch {
                display: none;
            }

            .menu-button-label {
                display: inline;
                line-height: #{$header-height}px;
                float: right;
                padding-right: px2rem(40);
            }

        }

        .menu-button {
            position: relative;
            padding: 9px 10px;
            border: 1px solid #ddd;
            background: #fff;
            border-radius: 4px;
            display: inline-block;
            vertical-align: middle;

            .icon-bar {
                display: block;
                width: 22px;
                height: 2px;
                border-radius: 1px;
                background-color: #888;
            }

            .icon-bar + .icon-bar {
                margin-top: 4px;
            }
        }
    }
</style>

<style lang="scss">
    .main-en {
        .header_is {
            .header_list_item {
                padding: 0 px2rem(0);

                /*&:nth-child(1) {*/
                /*    max-width: 98.25px;*/

                /*    .list {*/
                /*        min-width: 250px;*/
                /*    }*/
                /*}*/

                /*&:nth-child(2) {*/
                /*    max-width: 114.25px;*/

                /*    .list {*/
                /*        min-width: 114.25px;*/
                /*    }*/
                /*}*/

                /*&:nth-child(3) {*/
                /*    max-width: 114.25px;*/

                /*    .list {*/
                /*        min-width: 114.25px;*/
                /*    }*/
                /*}*/

                /*&:nth-child(4) {*/
                /*    max-width: 128.47px;*/

                /*    .list {*/
                /*        min-width: 128.47px;*/
                /*    }*/
                /*}*/

                /*&:nth-child(5) {*/
                /*    !*width: 123px;*!*/
                /*}*/
            }

            .header_warp {
                .header_list {
                    .header_list_item {
                        .titleHeader {
                            /*padding: 0;*/
                        }
                    }
                }

                @media screen and (max-width: 808px) {
                    .header_list {
                        padding-left: 0;
                    }
                }
            }
        }

        .header_is {
            .header_warp {
                .counting-container {
                    width: 330px;
                    margin-right: 10px;
                }
            }
        }

        @include bg {
            .header_is .header_warp .counting-container {
                display: none;
            }
        }

        @include md2 {
            .header_is .header_warp .lang-switch, .header_is .header_warp .lang-switch-item {
                display: none;
            }
        }
        
        @include  sm2{
            .header_is .header_warp .header_list{
                padding-left: 0;
            }

            .header_is .header_warp .header_list .header_list_item .titleHeader{
                padding: 0;
            }
        }
    }
</style>